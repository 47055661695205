<template>
  <CreateGroupPage />
</template>

<script>
  import CreateGroupPage from '../pages/groups/CreateGroupPage.vue';

  export default {
    components: {
      CreateGroupPage
    },
    name: 'EditGroup'
  };
</script>